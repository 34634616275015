@import '../../assets/scss/mixins';
.CpgPage {
  @include max_991 {
    .Fullpage {
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 198px;
      min-height: 60vh;
    }
  }
}

.cpg-shopper-patterns {
  &__image {
    position: absolute;
    top: 11.61%;
    left: 7.22%;
    width: 37.77%;
    height: auto;
    transition: opacity 0.5s, transform 0.5s;
    opacity: 0;
    transform: translateY(5%);

    @include max_991 {
      top: 10.19%;
      left: 33.06%;
      width: 84%;
      opacity: 1 !important;
      transform: none !important;
    }
  }

  @include max_991 {
    .Fullpage__mobile-circle {
      top: 8.63%;
    }

    padding-top: 198px;
    justify-content: flex-start;

    &__gradient {
      margin-top: 42px;
      text-align: center;

      & > div {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.cpg-second {
  @include max_991 {
    display: none;
  }

  .caption-one {
    margin-bottom: 0;
  }
}

.cpg-fourth {
  .caption-one {
    margin-bottom: 0;
  }
}

.cpg-third, .cpg-fifth {
  .caption-one {
    margin-bottom: 0;
  }
}

.cpg-map {
  &__item {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.6s;

    @include max_991 {
      height: auto;
      left: 0;
      top: 120px;
      width: 990px;
      opacity: 1 !important;
    }
  }

  .caption {
    transition: opacity 0.6s ease, transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height 0.6s ease,
      margin-top 0.6s, color 0.6s;
  }

  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: 990px auto;
    background-repeat: no-repeat;

    &.Fullpage {
      padding-top: 360px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
      width: 100%;
      height: 100%;
    }
  }
}

.cpg-third {
  @include max_991 {
    background-position-y: 120px;
    &.Fullpage {
      padding-top: 60px;
    }

    .caption-two {
      margin-bottom: 0;
    }

    .caption {
      font-size: 36px;
    }

    .Fullpage__mobile-circle {
      top: 53%;
    }

    &__captions {
      height: 694px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  .caption-one {
    @include _992 {
      margin-top: -70px;
      transform: none;
      margin-bottom: 0;
      opacity: 1;
    }

    @include max_991 {
      display: block;
      b {
        color: #0170e3 !important;
      }
    }

    strong,
    b {
      transition: 0.6s color;
      color: #a9b3c1;
    }
  }
}

.cpg-fourth {
  @include max_991 {
    display: none;
  }
}

.cpg-shops {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      width: 100%;
      height: 100%;
    }

    &.Fullpage {
      // z-index: 1;
      padding-top: 360px;
      // margin-top: -25vh;
    }
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @include max_991 {
      height: auto;
      transform: translateZ(0);
    }
  }

  .shop {
    &-one-state-one,
    &-one-state-two,
    &-one-active-one,
    &-two-state-one,
    &-two-active-one,
    &-two-active-two {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: opacity 0.3s, 0.3s transform;

      @include max_991 {
        height: auto;
        transform: translateZ(0);
      }
    }
  }

  &__roofs {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.3s;

    @include max_991 {
      height: auto;
      opacity: 1;
    }
  }
}

.cpg-sixth {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;

    &.Fullpage {
      padding-top: 360px;
    }

    .content {
      z-index: 3;
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
    z-index: 1;
  }

  .Fullpage__mobile-circle {
    @include max_991 {
      width: 180.93%;
      position: absolute;
      left: -52.93%;
      bottom: 4%;
      top: auto;
      border-radius: 50%;
      background-image: radial-gradient(
        33.23% 33.23% at 50% 50%,
        #f4f3f3 0%,
        rgba(244, 243, 243, 1) 65.64%,
        rgba(244, 243, 243, 0.52) 84.14%,
        rgba(244, 243, 243, 0) 100%
      );
    }
  }

  &__points {
    position: absolute;
    width: 36.66%;
    height: 56.64%;
    left: 10.55%;
    top: 18.2%;

    @include max_991 {
      top: 0;
      left: 11.73%;
      width: 77.86%;
      height: 46.92%;
    }
  }

  .MapPoint {
    transition: transform 0.3s;
    transform-origin: center 150%;
    transform: scale(0);

    @include max_991 {
      transform: none;
      opacity: 1;
    }
  }
}

.cpg-seventh {
  position: relative;
  // margin-top: 5.45vh;
  padding-top: 20.83vh;

  @include max_991 {
    padding-top: 32px;
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      display: block;
    }

    &_left {
      .cpg-seventh {
        &__text {
          margin-left: 100%;

          @include max_991 {
            margin-left: 0;
          }
        }
      }
    }

    &_right {
      .cpg-seventh {
        &__text {
          left: -100%;
          width: 100%;
          text-align: right;

          @include max_991 {
            z-index: 1;
            right: 15px;
            left: auto;
          }
        }
      }
    }

    &_party {
      padding-top: 67.56%;
    }

    &_sport {
      padding-top: 130%;
    }

    &_work {
      padding-top: 75.18%;
    }

    &_picnic {
      padding-top: 72.16%;
    }

    &_shop {
      padding-top: 123.61%;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 8px 28px rgba(35, 58, 80, 0.25);
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    transform: scale(1.1);

    @include max_991 {
      transform: none;
    }
  }

  &__parallax-text {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 0;
    font-weight: 800;
    font-size: 70px;
    @include fluid-type(1440px, 1920px, 70px, 91px);
    line-height: 88px;
    color: #cdd5dc;
    transform: translateY(-50%);

    @include max_991 {
      left: 16px;
      transform: none;
      top: auto;
      bottom: 6px;
      font-weight: bold;
      font-size: 46px;
      line-height: 100%;
      color: #ffffff;
      z-index: 2;
    }
  }

  &__text-column {
    @include max_991 {
      order: -1;
    }
  }

  &__row {
    &_party {
      margin-bottom: 2.87vh;
    }
    &_work {
      margin-top: -5.14vh;
      margin-bottom: 4.79vh;
    }
    &_picnic {
      margin-bottom: 4.79vh;
    }

    @include max_991 {
      margin: 0;
      margin-bottom: 39px;

      &_party,
      &_sport,
      &_work,
      &_picnic {
        margin: 0;
        margin-bottom: 39px;
      }
    }
  }

  &__text-container {
    position: sticky;
    margin-top: 3.95vh;
    margin-bottom: 3.95vh;
    top: 24.79vh;
    left: 0;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;

    img {
      display: block;
      transform: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .Fullpage__overlay {
    opacity: 1;
    transform: scale(1);
  }
}

.fullpage-animation {
  &-enter {
    .cpg-shopper-patterns {
      &__image {
        opacity: 0;
        transform: translateY(5%);
      }
    }
    .cpg-third {
      &__green {
        opacity: 0;
      }
    }

    .cpg-shops {
      &__roofs {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    .cpg-sixth {
      .MapPoint {
        transition: transform 0.3s;
        transform-origin: center 150%;
        transform: scale(0);
      }
    }

    &-active,
    &-done {
      .cpg-shopper-patterns {
        &__image {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .cpg-third {
        &__green {
          opacity: 1;
        }
      }

      .cpg-shops {
        &__roofs {
          opacity: 1;
          transition: opacity 0.3s;
        }
      }

      .cpg-sixth {
        .MapPoint {
          transition: transform 0.3s;
          transform-origin: center 150%;
          transform: scale(1);
        }
      }
    }
  }

  &-exit {
    .cpg-sixth {
      .MapPoint {
        transition: transform 0.3s;
        transform-origin: center 150%;
        transform: scale(1);
      }
    }

    &-active,
    &-done {
      .cpg-sixth {
        .MapPoint {
          transition: transform 0.3s;
          transform-origin: center 150%;
          transform: scale(0);
        }
      }
    }
  }
}

.fullpage-animation-bottom {
  .fullpage-animation {
    &-enter {
      .cpg-second, .cpg-fourth {
        &__image {
          opacity: 0;
        }

        .caption-one {
          margin-top: 0;
        }
      }

      .cpg-third, .cpg-fifth {
        .caption-one {
          margin-bottom: 0;
          margin-top: -70px;
          transform: none;
          opacity: 1;
          transition: 0s;

          strong,
          b {
            transition: 0.6s color;
            color: #a9b3c1;
          }
        }

        &__image {
          opacity: 1;
          transition: 0s;
        }
      }

      .cpg-shops {
        .shop {
          &-one {
            &-active {
              &-one {
                transform: translateY(-100px);
                opacity: 0;
              }
            }
          }
          &-two {
            &-active {
              &-one {
                transform: translateY(-100px);
                opacity: 0;
                transition-delay: 0.1s;
              }
              &-two {
                transform: translateY(-100px);
                opacity: 0;
                transition-delay: 0.2s;
              }
            }
          }
        }
      }
      .cpg-fourth {
        .cpg-shops__image {
          opacity: 0;
          transition: opacity 0.6s;
        }
        .cpg-shops__container {
          opacity: 0;
          transition: 0.6s;
        }
      }
      .cpg-fifth {
        .Fullpage__overlay {
          opacity: 1;
          transform: none;
        }
      }

      .cpg-sixth {
        .Fullpage__overlay {
          transform: none;
          opacity: 1;
        }
      }

      &-active,
      &-done {
        .cpg-second {
          &__image {
            opacity: 1;
          }
        }

        .cpg-third, .cpg-fifth {
          .caption-one {
            margin-bottom: 0;
            margin-top: -70px;
            transform: none;
            opacity: 1;
            transition: 0s;

            @include max_991 {
              display: none;
            }

            strong,
            b {
              transition: 0.6s color;
              color: #a9b3c1;
            }
          }

          .caption-two {
            position: absolute;

            @include max_991 {
              position: relative;
            }
          }

          &__image {
            opacity: 1;
            transition: 0s;
          }
        }

        .cpg-shops {
          .shop {
            &-one {
              &-active {
                &-one {
                  transform: translateY(0);
                  opacity: 1;
                }
              }
            }
            &-two {
              &-active {
                &-one {
                  transform: translateY(0);
                  opacity: 1;
                  transition-delay: 0.2s;
                }
                &-two {
                  transform: translateY(0);
                  opacity: 1;
                  transition-delay: 0.3s;
                }
              }
            }
          }
        }

        .cpg-fifth {
          .Fullpage__overlay {
            opacity: 1;
            transform: none;
          }
        }

        .cpg-fourth {
          .cpg-shops__image {
            opacity: 1;
            transition: opacity 0.6s;
          }

          .cpg-shops__container {
            opacity: 1;
            transition: 0.6s;
          }
        }

        .cpg-sixth {
          .Fullpage__overlay {
            transform: none;
            opacity: 1;
          }
        }
      }
    }

    &-exit {
      .cpg-second, .cpg-fourth {
        &__image {
          opacity: 1;
        }
        .caption {
          @include _992 {
            margin-top: -70px;
            transform: none;
            transition: opacity 0.6s ease, transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height 0.6s ease,
              margin-top 0.6s, color 0.6s;
            opacity: 1;
          }
        }
      }

      .cpg-fourth {
        .Fullpage__overlay {
          opacity: 1;
          transform: none;
        }
      }

      .cpg-fifth {
        .Fullpage__overlay {
          transform: none;
          opacity: 1;
        }
        .cpg-shops__container,
        .cpg-shops__image,
        .cpg-shops__roofs {
          opacity: 1;
          transition: 0.6s;
        }
      }

      &-active,
      &-done {
        .cpg-second, .cpg-fourth {
          &__image {
            opacity: 1;
          }

          .caption {
            @include _992 {
              transition: opacity 0.6s ease, transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height 0.6s ease,
                margin-top 0.6s, color 0.6s;
              transform: none;
              margin-top: -70px;
              opacity: 1;
            }

            strong,
            b {
              transition: 0.6s color;
              color: #a9b3c1;
            }
          }
        }

        .cpg-shops {
          .shop {
            &-one {
              &-state {
                &-one,
                &-two {
                  transform: translateY(-100px);
                  opacity: 0;
                }
                &-two {
                  transition-delay: 0.1s;
                }
              }
            }
            &-two {
              &-state {
                &-one {
                  transform: translateY(-100px);
                  opacity: 0;
                  transition-delay: 0.2s;
                }
              }
            }
          }
        }

        .cpg-fourth {
          .Fullpage__overlay {
            opacity: 1;
            transform: none;
          }
        }

        .cpg-fifth {
          .Fullpage__overlay {
            opacity: 0;
            transition: opacity 0.6s;
          }
          .cpg-shops__container,
          .cpg-shops__image,
          .cpg-shops__roofs {
            opacity: 0;
            transition: opacity 0.6s;
          }
        }
      }
    }
  }
}

.fullpage-animation-top {
  .fullpage-animation {
    &-enter {
      .cpg-second, .cpg-fourth {
        &__image {
          opacity: 1;
        }

        .caption {
          @include _992 {
            transform: none;
            margin-top: 0;
            color: #1a2b47;
            opacity: 1;
            transition: 0s;
            transition-delay: 0s;
            line-height: 100%;
          }

          strong,
          b {
            transition: 0s color;
            color: #0170e3;
          }
        }
      }

      .cpg-third {
        &__image {
          opacity: 0;
        }
      }

      .cpg-shops {
        .shop {
          &-one {
            &-state {
              &-one {
                transform: translateY(-100px);
                opacity: 0;
              }

              &-two {
                transform: translateY(-100px);
                opacity: 0;
                transition-delay: 0.1s;
              }
            }
          }
          &-two {
            &-state {
              &-one {
                transform: translateY(-100px);
                opacity: 0;
                transition-delay: 0.1s;
              }
            }
          }
        }
      }

      .cpg-fourth {
        .Fullpage__overlay {
          opacity: 1;
          transform: none;
        }
      }

      .cpg-fourth {
        .Fullpage__overlay {
          opacity: 1;
          transform: none;
        }
      }

      // .cpg-fifth {
      //   .Fullpage__overlay {
      //     transform: none;
      //     opacity: 1;
      //   }
      // }

      .cpg-fifth {
        .Fullpage__overlay {
          opacity: 0;
          transition: opacity 0.6s;
        }
        .cpg-shops__container,
        .cpg-shops__image,
        .cpg-shops__roofs {
          opacity: 0;
          transition: opacity 0.6s;
        }

        
      }

      &-active,
      &-done {
        .cpg-second, .cpg-fourth {
          &__image {
            opacity: 1;
          }
          .caption {
            @include _992 {
              transform: none;
              transition: color 0.6s, margin-top 0.6s;
              opacity: 1;
              transition-delay: 0s;
            }
          }
        }

        .cpg-third {
          &__image {
            opacity: 1;
          }
        }

        .cpg-shops {
          .shop {
            &-one {
              &-state {
                &-one {
                  transform: translateY(0);
                  opacity: 1;
                }
                &-two {
                  transform: translateY(0);
                  opacity: 1;
                  transition-delay: 0.1s;
                }
              }
            }
            &-two {
              &-state {
                &-one {
                  transform: translateY(0);
                  opacity: 1;
                  transition-delay: 0.1s;
                }
              }
            }
          }
        }

        .cpg-fourth {
          .Fullpage__overlay {
            opacity: 1;
            transform: none;
          }
        }

        .cpg-fourth {
          .Fullpage__overlay {
            opacity: 1;
            transform: none;
          }
        }

        // .cpg-fifth {
        //   .Fullpage__overlay {
        //     transform: none;
        //     opacity: 1;
        //   }
        // }

        .cpg-fifth {
          .Fullpage__overlay {
            opacity: 1;
            transition: opacity 0.6s;
          }
          .cpg-shops__container,
          .cpg-shops__image,
          .cpg-shops__roofs {
            opacity: 1;
            transition: opacity 0.6s;
          }
        }
      }
    }

    &-exit {
      .cpg-third, .cpg-fifth {
        .caption-one {
          @include _992 {
            margin-bottom: 0;
            transform: none;
            margin-top: -70px;
            opacity: 1;
            transition: color 0.6s, margin-top 0.6s;
            line-height: 100%;
          }
        }
        .caption-two {
          position: absolute;
          @include max_991 {
            position: relative;
          }
        }

        &__image {
          opacity: 1;
        }
      }

      .cpg-shops {
        .shop {
          &-one {
            &-active {
              &-one {
                transform: translateY(0);
                opacity: 1;
              }
            }
          }
          &-two {
            &-active {
              &-one {
                transform: translateY(0);
                opacity: 1;
                transition-delay: 0.1s;
              }
              &-two {
                transform: translateY(0);
                opacity: 1;
                transition-delay: 0.2s;
              }
            }
          }
        }
      }

      .cpg-fifth {
        .Fullpage__overlay {
          opacity: 1;
          transform: none;
        }
      }

      .cpg-fourth {
        .cpg-shops__image {
          opacity: 1;
          transition: opacity 0.6s;
        }
      }

      .cpg-shops__container {
        opacity: 1;
        transition: 0.6s;
      }

      .cpg-sixth {
        .Fullpage__overlay {
          transform: none;
          opacity: 1;
        }
      }

      &-active,
      &-done {
        .cpg-third, .cpg-fifth {
          .caption-one {
            margin-bottom: 0;
            transform: none;
            margin-top: 0;
            color: inherit;
            opacity: 1;
            line-height: 100%;

            strong,
            b {
              color: #0170e3;
            }
          }

          .caption-two {
            position: absolute;
            @include max_991 {
              position: relative;
            }
          }

          &__image {
            opacity: 1;
          }
        }

        .cpg-shops {
          .shop {
            &-one {
              &-active {
                &-one {
                  transform: translateY(-100px);
                  opacity: 0;
                }
              }
            }
            &-two {
              &-active {
                &-one {
                  transform: translateY(-100px);
                  opacity: 0;
                  transition-delay: 0.1s;
                }
                &-two {
                  transform: translateY(-100px);
                  opacity: 0;
                  transition-delay: 0.2s;
                }
              }
            }
          }
        }

        .cpg-fifth {
          .Fullpage__overlay {
            opacity: 1;
            transform: none;
          }
        }

        .cpg-fourth {
          .cpg-shops__image {
            opacity: 0;
            transition: opacity 0.6s;
          }
        }

        .cpg-shops__container {
          opacity: 0;
          transition: opacity 0.6s;
        }

        .cpg-sixth {
          .Fullpage__overlay {
            transform: none;
            opacity: 1;
          }
        }
      }
    }
  }
}
