@import '../../assets/scss/mixins';

.News {
  background-color: #fff;
  padding-bottom: 11.5vh;
  margin-bottom: -12.45vh;
  &-header {
    margin-bottom: 6.22vh;
    padding: 23.11vh 0 10.89vh;
    background-color: #f5f4f4;
    background-image: url('../../assets/images/news/news-header-background.svg');
    background-size: cover;
    background-position: center;

    @include max_991 {
      margin-bottom: 0;
      padding-bottom: 6.22vh;
    }

    .caption {
      margin-bottom: 0;

      @include max_991 {
        margin-bottom: 15px;
      }
    }

    &__description {
      font-size: 22px;
      line-height: 150%;
      color: #5f7391;
      @include fluid-type(1440px, 1920px, 22px, 27px);
    }
  }
}
