@import '../../../assets/scss/mixins';

.NewsItem {
  &__image {
    margin-bottom: 1.43vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 12px 35px rgba(70, 81, 91, 0.3);
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 61.89%;
    }
  }

  &__date {
    margin-bottom: 1.43vh;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #017be5;

    @include fluid-type(1440px, 1920px, 18px, 23px);
  }

  &__name {
    margin-bottom: 1.43vh;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #1a2b47;
    transition: color 0.3s;

    @include fluid-type(1440px, 1920px, 18px, 23px);
  }

  &:hover & {
    &__name {
      color: #017be5;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: #5f7391;

    @include fluid-type(1440px, 1920px, 16px, 21px);
  }
}

.NewsItem-column {
  margin-top: 6.7vh;
}
