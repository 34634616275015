@import '../../../assets/scss/mixins';
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    transition: opacity 0.3s;
    opacity: 0;
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: #fff;
    padding-top: 56px;

    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    width: 42%;
    height: 100%;
    overflow: auto;

    @include max_991 {
      padding-top: 73px;
      padding-bottom: 50px;
      width: 100%;
    }
  }

  &__content-inner {
    padding-left: 100px;
    padding-right: 185px;

    @include max_991 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__close-container {
    padding-right: 136px;
    margin-bottom: 21px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include max_991 {
      padding-right: 30px;
    }
  }

  &__close {
    cursor: pointer;
    margin: -15px;
    padding: 15px;
  }
}

.modal-transition {
  &-enter {
    .Modal {
      &__content {
        opacity: 0;
        transform: translateX(100%);
      }

      &__overlay {
        opacity: 0;
      }
    }

    &-active,
    &-done {
      .Modal {
        &__content {
          opacity: 1;
          transform: translateX(0);
        }
        &__overlay {
          opacity: 1;
        }
      }
    }
  }
  &-exit {
    .Modal {
      &__content {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &-active,
    &-done {
      .Modal {
        &__content {
          opacity: 0;
          transform: translate(100%);
        }
      }
    }
  }
}
