@import '../../../assets/scss/mixins';
.Button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-weight: bold;
  line-height: 100%;
  color: #fff;
  background: linear-gradient(145.8deg, #00a8ed 5.35%, #005ade 115.95%);
  border-radius: 600px;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.3s;

  &:not(&_gray) {
    box-shadow: 0px 10px 25px rgba(56, 65, 79, 0.16);
  }

  &__text {
    position: relative;
  }

  &_blue {
    &.Button {
      overflow: hidden;
      padding: 18px 0;

      .Button__circle {
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 0;
        height: 0;
        transition: width 0.7s, padding-top 0.7s;
        background: #0071cd;
      }

      &:hover {
        .Button__circle {
          width: calc(100% * 2.25);
          padding-top: calc(100% * 2.25);
        }
      }
    }
  }

  @include fluid-type(1440px, 1920px, 16px, 23px);

  &_gray {
    color: #413e52;
    background: #e2e4ea;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    border: 1px solid transparent;

    &:hover {
      color: #017be5;
      border-color: #017be5;
      background-color: #fff;
    }
  }
}

.Footer {
  .Button {
    &_gray {
      border: 0;
    }
  }
}
