@import '../../assets/scss/mixins';

.PrivacyPolicy {
  padding-top: 200px;

  &__list {
    padding-left: 30px;
    list-style: decimal;
  }

  @include max_991 {
    padding-top: 120px;
    .caption {
      font-size: 24px;
    }
  }
}
