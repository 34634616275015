@import '../../assets/scss/mixins';
.callback-form {
  .caption {
    margin-bottom: 0;
  }
}

.thank-you {
  padding-right: 185px;
  padding-left: 100px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include max_991 {
    padding: 0;
  }
}
