@import '../../assets/scss/mixins';
.LastCase {
  &__image {
    position: relative;
    box-shadow: 0px 8px 30px rgba(35, 58, 80, 0.25);
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    &::before {
      content: '';
      display: block;
      padding-top: 61.86%;
    }

    @include max_991 {
      margin-bottom: 30px;
    }
  }

  &__name {
    margin-bottom: 2.87vh;
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    color: #1a2b47;

    @include fluid-type(1440px, 1920px, 22px, 29px);

    @include max_991 {
      margin-bottom: 10px;
    }
  }

  &__description {
    margin: 0;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
