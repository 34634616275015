@import '../../assets/scss/mixins';

.SandwichMenu {
  @include max_991 {
    display: flex;
    justify-content: flex-end;
  }
  .sandwich {
    position: relative;
    z-index: 2;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    cursor: pointer;

    @include max_991 {
      width: 36px;
      padding: 0;
      align-items: flex-start;
    }

    &__line {
      width: 100%;
      height: 4px;
      background: #5f7391;
      border-radius: 999px;
      transition: all 0.3s;

      &_top,
      &_bottom {
        // transition: transform 0.3s, margin 0.3s;
      }

      &_middle {
        margin: 8px 0;
        width: calc(100% - 12px);
        // transition: margin 0.3s, height 0.3s, opacity 0.3s;
      }
    }

    &_opened {
      .sandwich {
        &__line {
          &_middle {
            height: 0;
            margin: 0;
            opacity: 0;
          }

          &_top {
            transform: rotate(45deg);
          }

          &_bottom {
            margin-top: -4px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &__menu-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: height 0.3s, width 0.3s;
    width: 42%;
    visibility: hidden;
    transition: visibility 0s 0.3s;

    @include max_991 {
      width: 100%;
    }
  }

  &__menu-wrapper {
    &.enter-done,
    &.enter-active {
      .sandwich-menu {
        visibility: visible;
        transition: visibility 0s;

        &__inner {
          transform: translateX(0);
        }

        &__overlay {
          transition: opacity 0.3s, visibility 0.3s;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.sandwich-menu {
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 16.39%;
    padding-right: 55.29%;
    transition: opacity 0.3s, transform 0.3s;
    background-color: #fff;
    transform: translateX(100%);
    opacity: 1;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @include max_991 {
      justify-content: flex-start;
      align-items: stretch;
      padding: 18px 32px 22px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(95, 115, 145, 0.33);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s 0.3s visibility, 0.3s opacity;
  }

  &.enter-active &,
  &.enter-done & {
    &__inner {
      opacity: 1;
    }
  }

  &__switcher {
    display: none;
    margin-right: 244px;
    margin-bottom: 40px;

    @include max_991 {
      display: block;
      width: 70px;
      margin: 0;
    }
  }

  &__link {
    margin-bottom: 32px;
    font-weight: bold;
    text-align: left;
    font-size: 36px;
    line-height: 120%;
    color: #a9b3c1;
    transition: color 0.3s;

    @include fluid-type(1440px, 1920px, 36px, 47px);

    @include max_991 {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #017be5;
    }
    .active {
      color: #1a2b47;
    }
  }

  &__contacts {
    margin-top: 92px;
    width: 100%;

    @include max_991 {
      border-top: 1px solid #a9b3c1;
      margin-top: auto;
      padding-top: 32px;
    }
  }

  &__contact {
    display: block;
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #000000;

    @include fluid-type(1440px, 1920px, 18px, 23px);

    @include max_991 {
      margin-top: 15px;
      text-align: center;
    }

    &:first-child {
      margin-bottom: 0;
    }
  }
}

.sandwich {
  &:hover & {
    &__line {
      background: #017be5;

      &_middle {
        width: 100%;
      }
    }
  }
}
