@import '../../assets/scss/mixins';
.Footer {
  position: fixed;
  background-color: #e7e8e9;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  will-change: transform;
  box-shadow: inset 0px 11px 37px rgba(121, 138, 148, 0.2);
  overflow: hidden;

  &__mobile-switcher {
    display: none;
  }

  @include max_991 {
    position: relative;
    height: auto;
  }

  &-bottom {
    position: relative;
    padding: 30px 0;
    background: #284058;

    &__agreement {
      line-height: 150%;
      color: #a9b3c1;
      @include fluid-type(1440px, 1920px, 14px, 18px);
    }

    &__privacy {
      display: block;
      margin-top: 15px;
      line-height: 130%;
      text-decoration: underline;
      color: #a9b3c1;
      @include fluid-type(1440px, 1920px, 18px, 23px);
      transition: color 0.3s;

      &:hover {
        color: #fff;
      }
    }

    &__bottom {
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      line-height: 150%;
      color: #a9b3c1;
      @include fluid-type(1440px, 1920px, 14px, 18px);

      @include max_991 {
        border: 0;
        margin: 0;
        padding: 0;
      }
    }

    &__skolkovo,
    &__author {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        display: block;
        margin-right: 22px;
      }
    }

    &__author {
      justify-content: flex-end;
      img {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  &__inner {
    height: auto;
    will-change: transform;
  }

  &__link {
    margin-top: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #a6a8b8;
    transition: color 0.3s;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }

    &:hover {
      color: #fff;
    }

    @include max_991 {
      margin-top: 32px;

      &:nth-child(2) {
        margin-top: 32px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    @include fluid-type(1440px, 1920px, 16px, 21px);
  }

  &__contact {
    display: block;
    margin-top: 24px;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;

    @include fluid-type(1440px, 1920px, 16px, 21px);

    @include max_991 {
      margin-top: 32px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @include max_991 {
    &__privacy {
      order: 2;
    }
    &__menu {
      order: -1;
      margin-bottom: 32px;
    }

    .MainMenu {
      display: block;
      &__item {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__desktop-switcher {
      display: none;
    }

    &__mobile-switcher {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 32px;
      .LangSwitcher {
        width: 50%;
      }
    }

    &__menu {
      & > .row {
        align-items: flex-start !important;
      }
    }

    &__skolkovo-col {
      padding: 12px 0;
      margin: 32px 0;
      order: -1;
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-left: 0;
      border-right: 0;
    }

    &__button-column {
      text-align: center;
      margin-bottom: 32px;

      .Button {
        width: 60%;
        margin: 0 auto;
      }
    }

    &-bottom {
      &__copy {
        margin-bottom: 32px;
        text-align: center;
      }

      &__author {
        justify-content: center;
      }
    }
  }
}
