@import '../../assets/scss/mixins', '../../assets/scss/variables';
$animationDuration: 0.6s;

.RetailPage {
  @include max_991 {
    .Fullpage {
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 300px;
      min-height: 60vh;
    }
  }
}

.retail-profitable-locations {
  &__points {
    position: absolute;
    left: 10.69%;
    top: 17.6%;
    width: 29.09%;
    height: 53.05%;

    @include max_991 {
      width: 61.86%;
      height: 27.12%;
      left: 25.86%;
      top: 14.54%;
    }

    .MapPoint {
      @include _992 {
        transition: transform 0.3s;
        transform-origin: center 150%;
        transform: scale(0);
      }
    }
  }

  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;

    &.Fullpage {
      padding-top: 300px;
    }
  }
}

.retail-heatmap {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;
  }
  &__hex {
    position: absolute;
    top: 11.01%;
    left: 4.44%;
    width: 42.96%;
    height: 84.06%;

    @include max_991 {
      left: 13.86%;
      top: 9.14%;
      width: 91.46%;
      height: 58.32%;
    }

    svg {
      margin: 0 auto;
      display: block;
      width: auto;
      height: 100%;
      transform-box: fill-box;

      path {
        @include _992 {
          transition: transform 0.3s;
          transform: scale(0);
          transform-box: fill-box;
          transform-origin: center;
        }
      }
    }
  }

  @include max_991 {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      width: 100%;
      height: 100%;
    }
  }
}

.retail-prediction-model {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;
    margin-top: -13vh;
  }

  &__area {
    position: absolute;
    display: block;
    transform: scale(0.8);
    opacity: 0;
    transition: 0.3s transform, 0.3s opacity;

    @include max_991 {
      opacity: 1;
      transform: none;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &_big {
      width: 35.48%;
      height: 47.92%;
      left: 5.97%;
      top: 28.1%;

      @include max_991 {
        width: 78.93%;
        height: 34.78%;
        left: 9.92%;
        top: 22.68%;
      }
    }
    &_small {
      position: absolute;
      top: 40.86%;
      left: 17.46%;
      width: 14.86%;
      height: 23.47%;
      transition-delay: 0.1s;

      @include max_991 {
        width: 33.06%;
        height: 16.94%;
        left: 37.46%;
        top: 32.23%;
      }
    }
  }

  &__points {
    position: absolute;
    left: 13.95%;
    top: 42.03%;
    width: 23.54%;
    height: 13.77%;

    @include max_991 {
      width: 52.26%;
      height: 9.89%;
      left: 31.46%;
      top: 33.88%;
    }
  }

  .MapPoint {
    @include _992 {
      transition: transform 0.3s;
      transform-origin: center 150%;
      transform: scale(0);
    }
  }

  @include max_991 {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      width: 100%;
      height: 100%;
    }
  }
}

.retail-workflow {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;
  }

  &__mac {
    position: absolute;
    width: 53.88%;
    height: 72.33%;
    left: 0;
    top: 10.65%;

    @include max_991 {
      width: 115.2%;
      height: 51.12%;
      left: -20%;
      top: 4%;
    }

    img {
      margin: 0 auto;
      display: block;
      width: auto;
      height: 100%;
      max-height: 100%;
    }
  }

  &__phone {
    position: absolute;
    width: 19.93%;
    height: 18.56%;
    left: 21.94%;
    top: 65.5%;
    transition-delay: 0.1s;

    @include max_991 {
      width: 42.4%;
      height: 12.89%;
      left: 46%;
      top: 40.23%;
    }

    img {
      margin: 0 auto;
      display: block;
      width: auto;
      height: 100%;
    }
  }

  &__mac,
  &__phone {
    @include _992 {
      opacity: 0;
      transform: translateY(10%);
      transition: opacity 0.3s, transform 0.3s;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(31.9% 83.03% at 26.51% 53.21%, rgba(244, 244, 244, 0.63) 0%, #f4f3f3 100%);
    transition: opacity 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99);
    opacity: 0;
    transform: scale(2);

    @include max_991 {
      display: none;
    }
  }

  @include max_991 {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      width: 100%;
      height: 100%;
    }
  }
}

.retail-circles {
  @include max_991 {
    background: url('../../assets/images/common/main_map.svg');
    background-size: auto 70vh;
    background-repeat: no-repeat;
    margin-top: -9vh;

    &.Fullpage {
      padding-top: 350px;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(31.9% 83.03% at 26.51% 53.21%, rgba(244, 244, 244, 0.63) 0%, #f4f3f3 100%);
    transition: opacity 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99);
    opacity: 0;
    transform: scale(2);

    @include max_991 {
      display: none;
    }
  }

  &__circles {
    position: absolute;
    left: 5.9%;
    top: 16.04%;
    width: 39.58%;
    height: 58.56%;

    @include max_991 {
      left: 3.51%;
      top: 5.14%;
      width: 92.53%;
      height: 48.08%;
    }
  }

  &__circle {
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 18px 28px rgba(33, 78, 32, 0.24);
    transition: left $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99),
      top $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99),
      opacity $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99);
    opacity: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      @include fluid-type(1440px, 1920px, 18px, 23px);
      line-height: 23px;
      color: #fff;

      @include max_991 {
        font-size: 11px;
        line-height: 14px;
      }
    }

    &_green {
      left: 0;
      top: 30.87%;
      width: 29.47%;
      background-color: #32ac59;
    }

    &_yellow {
      left: 30.28%;
      top: 42.74%;
      width: 48.94%;
      background: linear-gradient(180deg, #f6c01d 0%, #f8d91c 100%);
      box-shadow: 0px 28px 26px rgba(98, 88, 63, 0.13);
    }

    &_blue {
      top: 0;
      left: 60.87%;
      width: 38.94%;
      background: #0192cd;
    }

    @include max_991 {
      opacity: 1;
      z-index: 1;

      &_green {
        left: 8.77%;
        top: 37.01%;
      }
      &_blue {
        top: 10.22%;
        left: 52.1%;
      }
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 82.1%;
    top: 50.92%;
    width: 26.66%;
    height: 27.38%;
    transition: opacity 0.3s cubic-bezier(0.17, 0.24, 0.19, 0.99);
    opacity: 0;

    @include max_991 {
      display: none;
    }

    svg {
      width: 100%;
      height: auto;
      display: block;

      .line,
      .angle {
        stroke-dasharray: 100%;
        stroke-dashoffset: -100%;
        transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
      }

      .angle {
        stroke-dasharray: 0% 100%;
        stroke-dashoffset: -50%;
        transition-delay: 0.1s;
      }
    }
  }

  @include max_991 {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      width: 100%;
      height: 100%;
    }

    .content {
      z-index: 2;
    }
  }
}

.retail-data-sources {
  padding-top: 17.84vh;

  @include max_991 {
    padding-top: 45px;
  }
}

.retail-morph {
  background-image: url('../../assets/images/retail/morph-points.svg');
  background-size: 55.46% auto;
  background-position: center 55%;
  background-repeat: no-repeat;

  @include max_991 {
    background-image: none;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include max_991 {
      flex-wrap: wrap;
      background-image: url('../../assets/images/retail/morph-points_mobile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 58%;
    }
  }

  &__column {
    flex-shrink: 0;
    width: 27.83%;

    @include max_991 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__item {
    margin-bottom: 7.9vh;

    &:last-child {
      margin-bottom: 0;
    }

    @include max_991 {
      width: 45%;
      margin-bottom: 0;
    }
  }

  &__circle {
    position: relative;
    margin: 0 auto 1.55vh;
    width: 44.44%;
    background-color: #fff;
    border-radius: 50%;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    svg {
      width: 40%;
      height: auto;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  &__name {
    text-align: center;
    color: #1a2b47;
    font-weight: 500;
    line-height: 150%;
    @include fluid-type(1440px, 1920px, 18px, 23px);

    @include max_991 {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__container {
    position: relative;
    width: 36.9%;

    @include max_991 {
      width: 63.26%;
      margin: 34px auto;
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    line-height: 140%;
    @include fluid-type(1440px, 1920px, 20px, 27px);

    @include max_991 {
      font-size: 12px;
      line-height: 140%;
    }
  }
}

.retail-clients {
  margin-top: 16.52vh;

  @include max_991 {
    margin-top: 60px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include max_991 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      img {
        width: 45%;
      }
    }
  }
}

.retail-cases {
  margin-top: 13.41vh;
}

.retail-analysts {
  margin-top: 13.41vh;

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background: #ffffff;
    box-shadow: 0px 18px 34px rgba(61, 78, 95, 0.18);
    border-radius: 10px;
    padding: 57px 18px;
    padding-top: 57px;

    &:hover .retail-analysts {
      &__logo {
        transform: scale(0.7);
      }

      &__description {
        opacity: 0;
      }

      &__link {
        opacity: 1;
      }
    }
  }

  &__logo {
    margin: 0 auto;
    width: 57%;

    img {
      width: 100%;
      height: auto;
    }

    transition: transform 0.3s;
    will-change: transform;
  }

  &__text {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 40px;

    @include max_991 {
      display: none;
    }
  }

  &__description {
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    color: #5f7391;
    transition: opacity 0.3s;

    @include fluid-type(1440px, 1920px, 14px, 18px);
  }

  &__link {
    position: absolute;
    // margin-top: 20px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #1a2b47;
    opacity: 0;
    transition: opacity 0.3s;

    svg {
      margin-left: 7px;
    }

    @include fluid-type(1440px, 1920px, 16px, 21px);
  }

  @include max_991 {
    .row {
      margin-top: -2.56vw;
    }
    .col-6 {
      margin-top: 2.56vw;
    }
  }
}

@include _992 {
  .fullpage-animation {
    &-enter {
      .MapPoint {
        transform: scale(0);

        .retail-heatmap {
          path {
            transform: scale(0);
          }
        }
        .retail-prediction-model {
          &__area {
            opacity: 0;
            transform: scale(0.8);

            &_small {
              transition-delay: 0.1s;
            }
          }
        }
        .retail-workflow {
          &__mac,
          &__phone {
            opacity: 0;
            transform: translateY(10%);
          }

          &__phone {
            transition-delay: 0.2s;
          }

          &__background {
            transform: scale(2);
            opacity: 0;
          }
        }
        .retail-circles {
          &__circle {
            opacity: 0;
            &_green {
              left: 0;
              top: 30.87%;
            }

            &_yellow {
              left: 30.28%;
              top: 42.74%;
              width: 48.94%;
            }

            &_blue {
              top: 0;
              left: 60.87%;
            }

            &__arrow {
              opacity: 0;
              svg {
                .line,
                .angle {
                  stroke-dasharray: 100%;
                  stroke-dashoffset: -100%;
                }

                .angle {
                  stroke-dasharray: 0% 100%;
                  stroke-dashoffset: -50%;
                  transition-delay: 0.1s;
                }
              }
            }
          }
        }
      }

      &-active,
      &-done {
        .MapPoint {
          transform: scale(1);
        }
        .retail-heatmap {
          path {
            transform: scale(1);
          }
        }

        .retail-prediction-model {
          &__area {
            opacity: 1;
            transform: scale(1);

            &_small {
              transition-delay: 0.1s;
            }
          }
        }

        .retail-workflow {
          &__mac,
          &__phone {
            opacity: 1;
            transform: translateY(0);
          }

          &__phone {
            transition-delay: 0.2s;
          }

          &__background {
            transform: scale(1);
            opacity: 1;
          }
        }

        .retail-circles {
          &__circle {
            opacity: 1;
            &_green {
              left: 8.77%;
              top: 37.01%;
            }
            &_blue {
              top: 10.22%;
              left: 52.1%;
            }
          }

          &__arrow {
            opacity: 1;
            svg {
              .line,
              .angle {
                stroke-dasharray: 100%;
                stroke-dashoffset: 0;
              }

              .angle {
                stroke-dasharray: 100% 100%;
                stroke-dashoffset: 0%;
                transition-delay: 0.1s;
              }
            }
          }
        }
      }
    }

    &-exit {
      .MapPoint {
        transform: scale(1);
      }
      .retail-heatmap {
        path {
          transform: scale(1);
        }
      }

      .retail-prediction-model {
        &__area {
          opacity: 1;
          transform: scale(1);

          &_small {
            transition-delay: 0s;
          }

          &_big {
            transition-delay: 0.1s;
          }
        }
      }

      .retail-workflow {
        &__mac,
        &__phone {
          opacity: 1;
          transform: translateY(0);
        }

        &__mac {
          transition-delay: 0.2s;
        }

        &__phone {
          transition-delay: 0;
        }

        &__background {
          transform: scale(1);
          opacity: 1;
          transition-delay: 0.2s;
        }
      }

      .retail-circles {
        &__circle {
          opacity: 1;
          &_green {
            left: 8.77%;
            top: 37.01%;
          }
          &_blue {
            top: 10.22%;
            left: 52.1%;
          }
        }
      }

      &-active,
      &-done {
        .MapPoint {
          transform: scale(0);
        }
        .retail-heatmap {
          path {
            transform: scale(0);
          }
        }

        .retail-prediction-model {
          &__area {
            opacity: 0;
            transform: scale(0.8);

            &_small {
              transition-delay: 0s;
            }

            &_big {
              transition-delay: 0.1s;
            }
          }
        }

        .retail-workflow {
          &__mac,
          &__phone {
            opacity: 0;
            transform: translateY(-10%);
          }

          &__mac {
            transition-delay: 0.2s;
          }

          &__phone {
            transition-delay: 0;
          }

          &__background {
            transform: scale(2);
            opacity: 0;
            transition-delay: 0.2s;
          }
        }

        .retail-circles {
          &__circle {
            opacity: 0;
            &_green {
              left: 0;
              top: 30.87%;
            }

            &_yellow {
              left: 30.28%;
              top: 42.74%;
              width: 48.94%;
            }

            &_blue {
              top: 0;
              left: 60.87%;
            }
          }
        }
      }
    }
  }

  .fullpage-animation-bottom {
    .fullpage-animation {
      &-enter {
        .retail-workflow {
          &__background {
            transform: scale(2);
            opacity: 0;
            transition-delay: 0.2s;
          }
        }

        .retail-circles {
          &__background {
            transform: scale(1);
            opacity: 1;
            transition: 0s;
          }
        }

        &-active,
        &-done {
          .retail-workflow {
            &__background {
              transform: scale(1);
              opacity: 1;
              transition-delay: 0.2s;
            }
          }

          .retail-circles {
            &__background {
              transform: scale(1);
              opacity: 1;
              transition: 0s;
            }
          }
        }
      }

      &-exit {
        .retail-circles {
          &__background {
            transform: scale(1);
            opacity: 1;
          }
        }
        &-active,
        &-done {
          .retail-workflow {
            &__background {
              transform: scale(1);
              opacity: 1;
              transition-delay: 0.2s;
            }
          }
          .retail-circles {
            &__background {
              transform: scale(2);
              opacity: 0;
              transition-delay: 0.2s;
            }
          }
        }
      }
    }
  }

  .fullpage-animation-top {
    .fullpage-animation {
      &-enter {
        .retail-workflow {
          &__background {
            transform: scale(1);
            opacity: 1;
            transition-delay: 0;
            transition: 0s;
          }
        }
        &-active,
        &-done {
          .retail-workflow {
            &__background {
              transform: scale(1);
              opacity: 1;
              transition-delay: 0;
              transition: 0s;
            }
          }
        }
      }

      &-exit {
        .retail-circles {
          &__background {
            transform: scale(1);
            opacity: 1;
          }
        }
        &-active,
        &-done {
          .retail-circles {
            &__background {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
