@import '../../assets/scss/mixins';
.ScheduleForm {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max_991 {
    padding-top: 42px;
    padding-bottom: 42px;
    height: auto;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__form {
    margin-top: 50px;

    @include max_991 {
      margin-top: 32px;
    }

    [class*='col-'] {
      margin-top: 60px;

      @include max_991 {
        margin-top: 32px;
      }
    }

    .row {
      margin: -60px -15px 0;

      @include max_991 {
        margin: -32px -15px 0;
      }
    }
    .row > * {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__message {
    font: inherit;
    display: block;
    width: 100%;
    min-height: 117px;
    font-size: 16px;
    line-height: 100%;
    padding: 16px;
    border: 1px solid #a9b3c1;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;
    outline: none;
    transition: border-color 0.3s;

    &:focus {
      border-color: #5f7391;
    }
  }
}
