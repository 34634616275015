@import '../../assets/scss/mixins';

.Header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 11px 0;
  z-index: 5;
  padding-bottom: 34px;
  background-image: linear-gradient(180deg, #f5f4f4 37.39%, rgba(245, 244, 244, 0.78) 61.42%, rgba(245, 244, 244, 0) 100%);
  margin-bottom: -23px;

  &__logo {
    display: block;
    width: 100%;
    height: auto;

    @include max_991 {
      width: calc(100% - 30px);
    }
  }

  &__sandwich {
    display: none;

    @include max_991 {
      align-self: center;
      display: block;
      width: 36px;
      margin-left: auto;
      padding: 0;
    }
  }
}
