@import '../../../assets/scss/mixins';
.Case-header {
  margin-bottom: 6.7vh;
  padding-top: 16.88vh;
  padding-bottom: 14.61vh;
  background-color: #f5f4f4;

  @include max_991 {
    margin-bottom: 0;
    padding-bottom: 84px;

    .caption {
      font-size: 34px;
    }
  }

  &__back {
    margin-bottom: 4.79vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #1a2b47;

    @include fluid-type(1440px, 1920px, 16px, 21px);

    svg {
      margin-right: 12px;
    }

    &_right {
      margin-bottom: 0;
      text-align: right;
      justify-content: flex-end;

      @include max_991 {
        margin-top: 45px;
      }

      svg {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }

  &__name-column {
    @include max_991 {
      order: -1;
    }
  }

  &__logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    box-shadow: 0px 18px 32px rgba(35, 58, 80, 0.08);
    border-radius: 10px;

    @include max_991 {
      margin-bottom: 30px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 61.91%;
    }
  }

  &__option {
    margin-bottom: 4.79vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    color: #1a2b47;
    @include fluid-type(1440px, 1920px, 22px, 29px);

    span {
      min-width: 8.33vw;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      color: #a9b3c1;
      @include fluid-type(1440px, 1920px, 18px, 23px);

      @include max_991 {
        min-width: 120px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.CaseDetail {
  &__objective {
    margin-bottom: 6.7vh;
  }
}
