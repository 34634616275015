@import '../../../assets/scss/mixins';
.Map {
  position: relative;
  box-shadow: inset -100px -100px 150px #e9eaeb, inset 100px 100px 150px #e9eaeb;
  height: 71.85vh;
  margin-bottom: -12.45vh;

  @include max_991 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: auto;
  }

  // &::after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 300px;
  //   background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.37%, #ffffff 98.47%);
  //   z-index: 3;
  // }

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 84px;
  //   background: linear-gradient(180deg, #e9eaeb 0%, rgba(233, 234, 235, 0) 100%);
  //   z-index: 3;
  // }

  // &::after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 84px;
  //   background: linear-gradient(0deg, #e9eaeb 0%, rgba(233, 234, 235, 0) 100%);
  //   z-index: 3;
  // }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 3em;
    padding-left: 1em;
    text-align: center;
    padding-right: 50%;

    @include max_991 {
      display: none;
    }
  }
}

.Map__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(59.76% 38.74% at 51.1% 46.67%, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  overflow: hidden;

  .mapboxgl-marker {
    top: -50px;
  }

  @include max_991 {
    height: 450px;
  }
}

.map-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  background-color: #017be5;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 4;

  @include max_991 {
    width: 39px;
    height: 39px;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(0deg, #017be5, #017be5), #017be5;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    z-index: 5;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    z-index: 6;

    @include max_991 {
      width: 30px;
      height: 30px;
    }
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 59px;
    height: 59px;
    border-radius: 59px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-right: 18px;
    background: #017be5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    transition: 0.3s;
    opacity: 0;
    color: #fff;
    white-space: nowrap;

    @include max_991 {
      width: 39px;
      height: 39px;
    }
  }

  &__polygon {
    position: absolute;
    left: 50%;
    bottom: -28px;
    transform: translateX(-50%);

    @include max_991 {
      width: 38px;
      height: 38px;
      bottom: -27px;
    }
  }

  &:hover {
    &__text {
      opacity: 1;
      width: auto;
    }
  }
}

.map-marker:hover .map-marker__text {
  padding-left: 72px;
  opacity: 1;
  width: auto;

  @include max_991 {
    padding-left: 42px;
  }
}

.map-contacts {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  @include max_991 {
    position: relative;
    order: -1;
  }

  &__inner {
    left: 0;
    top: 7.9vh;
    width: 100%;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 8px 30px rgba(35, 58, 80, 0.25);
    border-radius: 10px;
    z-index: 4;
    padding-top: 38px;
    padding-left: 45px;
    padding-right: 23px;
    padding-bottom: 52px;

    @include max_991 {
      position: relative;
      top: 0;
      left: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 40px 0;
    }
  }

  &__caption {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: #413e52;

    @include fluid-type(1440px, 1920px, 36px, 47px);
  }

  &__email {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    color: #1a2b47;
    @include fluid-type(1440px, 1920px, 22px, 29px);
  }

  &__name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #a9b3c1;
    @include fluid-type(1440px, 1920px, 18px, 23px);

    b {
      color: #017be5;
    }
  }

  &__phone {
    font-size: 18px;
    line-height: 150%;
    color: #5f7391;
    @include fluid-type(1440px, 1920px, 18px, 23px);
  }

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mapboxgl-ctrl-top-right {
  z-index: 8;
}
