@import '../../../assets/scss/mixins';
.CaseItem {
  min-height: 100%;
  box-shadow: 0px 18px 34px rgba(61, 78, 95, 0.18);
  border-radius: 10px;
  padding: 32px;

  @include max_991 {
    padding: 16px;
  }

  &__logo {
    margin-bottom: 10px;
    height: 145px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include max_991 {
      justify-content: center;
    }

    img {
      display: block;
      max-height: 100%;
      width: auto;
      filter: grayscale(1);
      transition: filter 0.3s;
    }
  }

  &__name {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #1a2b47;

    @include fluid-type(1440px, 1920px, 36px, 47px);
    transition: color 0.3s;
  }

  &__description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 150%;
    color: #5f7391;
    padding-right: 172px;

    @include fluid-type(1440px, 1920px, 16px, 21px);

    @include max_991 {
      padding-right: 0;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #1a2b47;
    opacity: 0;
    transition: opacity 0.3s;

    @include fluid-type(1440px, 1920px, 16px, 21px);

    svg {
      margin-left: 12px;
    }
  }

  &:hover & {
    &__logo {
      img {
        filter: grayscale(0);
      }
    }

    &__name {
      color: #017be5;
    }

    &__link {
      opacity: 1;
    }
  }
}
