@import '../../assets/scss/mixins';

.AboutUs {
  .caption_1 {
    text-align: right;
    @include max_991 {
      text-align: left;
    }
  }

  .paragraph {
    margin-top: 0;
  }
}

.about-first {
  padding-top: 24.67vh;
  background-image: url('../../assets/images/common/background-circles.svg');
  padding-bottom: 15.32vh;
  background-size: cover;
  background-position: center;

  @include max_991 {
    padding-bottom: 44px;
  }
}
