@import '../../assets/scss/mixins';
.FullpageWrapper {
  position: relative;

  .content {
    position: relative;
    z-index: 2;

    @include max_991 {
      z-index: 3;
    }
  }

  &__inner {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
  }

  &__background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__background {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    @include max_991 {
      height: 70vh;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(59.75% 59.75% at 50% 50%, rgba(247, 249, 250, 0) 0%, #f4f3f3 100%);
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    will-change: transform;
    transform-style: preserve-3d;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include max_991 {
        opacity: 0.7;
        object-position: left;
      }
    }
  }

  &__scroll-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;

    @include max_991 {
      display: none;
    }
  }

  &__dots {
    position: sticky;
    top: 0;

    left: auto;
    width: 22px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @include max_991 {
      display: none;
    }

    &-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      right: 9.37%;
      top: 0;
      width: 22px;
      height: 100%;
      z-index: 1;

      @include max_991 {
        display: none;
      }
    }
  }

  &__dot {
    position: relative;
    margin-bottom: 30px;
    width: 22px;
    height: 22px;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #a6a8b8;
      transition: background-color 0.3s;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #0178e4;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0);
      transition: transform 0.3s;
      box-sizing: border-box;
    }

    &_active {
      &::before {
        background-color: #0178e4;
      }
      &::after {
        transform: scale(1);
      }
    }

    &:hover {
      &::before {
        background-color: #0178e4;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .content {
    width: 100%;
  }

  @include max_991 {
    &__circle-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__circle-container {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
    &__mobile-circle {
      display: block;
      position: absolute;
      width: 142.93%;
      border-radius: 50%;
      left: -30.93%;
      top: 31.63vh;
      background: radial-gradient(
        33.23% 33.23% at 50% 50%,
        #f4f3f3 0%,
        rgba(244, 243, 243, 0.75) 65.64%,
        rgba(244, 243, 243, 0.52) 84.14%,
        rgba(244, 243, 243, 0) 100%
      );

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }

  $animationDuration: 0.6s;
  $offsetY: 200px;
  $lineHeightMin: 150%;
  $lineHeightMax: 150%;

  .caption {
    line-height: 100% !important;
    transition: opacity $animationDuration ease, transform $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99),
      line-height $animationDuration ease;
    opacity: 0;
    transform: translateY($offsetY);
    will-change: opacity, transform, line-height;

    @include max_991 {
      line-height: 100% !important;
    }
  }

  .paragraph {
    line-height: $lineHeightMin !important;
    transition: opacity $animationDuration ease, transform $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99),
      line-height $animationDuration ease;
    opacity: 0;
    transform: translateY($offsetY);
    transition-delay: 0.05s;
    will-change: opacity, transform, line-height;

    @include max_991 {
      line-height: $lineHeightMin !important;
    }
  }

  .Button,
  .gradient-text {
    opacity: 0;
    transform: translateY($offsetY);
    transition: opacity $animationDuration, transform $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99);
    transition-delay: 0.1s;
    will-change: opacity, transform, line-height;
  }

  &.fullpage-animation-bottom {
    .fullpage-animation {
      &-enter {
        .caption {
          transform: translateY($offsetY);
          line-height: 150%;
          opacity: 0;
        }
        .paragraph {
          transform: translateY($offsetY);
          line-height: $lineHeightMax;
          opacity: 0;
        }

        .Button,
        .gradient-text {
          opacity: 0;
          transform: translateY($offsetY);
        }

        &-active,
        &-done {
          .caption {
            transform: translateY(0);
            line-height: 100%;
            opacity: 1;
          }
          .paragraph {
            transform: translateY(0);
            line-height: $lineHeightMin;
            opacity: 1;
          }
          .Button,
          .gradient-text {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      &-exit {
        .caption {
          transform: translateY(0);
          line-height: $lineHeightMin;
          opacity: 1;
        }
        .paragraph {
          transform: translateY(0);
          line-height: $lineHeightMin;
          opacity: 1;
        }

        .Button,
        .gradient-text {
          opacity: 1;
          transform: translateY(0);
        }

        &-active,
        &-done {
          .caption,
          .paragraph,
          .Button,
          .gradient-text {
            transition: opacity $animationDuration - 0.2s, transform $animationDuration cubic-bezier(0.17, 0.24, 0.19, 0.99),
              line-height $animationDuration ease;
          }
          .caption {
            transform: translateY(-$offsetY);
            line-height: 100%;
            opacity: 0;
          }
          .paragraph {
            transform: translateY(-$offsetY);
            line-height: $lineHeightMax;
            opacity: 0;
          }

          .Button,
          .gradient-text {
            opacity: 0;
            transform: translateY(-$offsetY);
          }
        }
      }
    }
  }

  &.fullpage-animation-top {
    .fullpage-animation {
      &-enter {
        .caption {
          transform: translateY(-$offsetY);
          line-height: 150%;
          opacity: 0;
        }
        .paragraph {
          transform: translateY(-$offsetY);
          line-height: $lineHeightMax;
          opacity: 0;
        }

        .Button,
        .gradient-text {
          opacity: 0;
          transform: translateY(-$offsetY);
        }

        &-active,
        &-done {
          .caption {
            transform: translateY(0);
            line-height: 100%;
            opacity: 1;
          }
          .paragraph {
            transform: translateY(0);
            line-height: $lineHeightMin;
            opacity: 1;
          }
          .Button,
          .gradient-text {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      &-exit {
        .caption {
          transform: translateY(0);
          line-height: $lineHeightMin;
          opacity: 1;
          transition: opacity $animationDuration - 0.2s cubic-bezier(0.17, 0.24, 0.19, 0.99),
            transform $animationDuration - 0.1s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height $animationDuration ease;
          transition-delay: 0s;
        }
        .paragraph {
          transform: translateY(0);
          line-height: $lineHeightMin;
          transition: opacity $animationDuration - 0.2s cubic-bezier(0.17, 0.24, 0.19, 0.99),
            transform $animationDuration - 0.1s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height $animationDuration ease;
          transition-delay: 0s;
          opacity: 1;
        }

        .Button,
        .gradient-text {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0s;
        }

        &-active,
        &-done {
          .caption {
            transform: translateY($offsetY);
            line-height: 150%;
            opacity: 0;
            transition: opacity $animationDuration - 0.2s cubic-bezier(0.17, 0.24, 0.19, 0.99),
              transform $animationDuration - 0.1s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height $animationDuration ease;
            transition-delay: 0s;
          }
          .paragraph {
            transform: translateY($offsetY);
            line-height: $lineHeightMax;
            transition: opacity $animationDuration - 0.2s cubic-bezier(0.17, 0.24, 0.19, 0.99),
              transform $animationDuration - 0.1s cubic-bezier(0.17, 0.24, 0.19, 0.99), line-height $animationDuration ease;
            transition-delay: 0s;
            opacity: 0;
          }

          .Button,
          .gradient-text {
            opacity: 0;
            transform: translateY($offsetY);
            transition-delay: 0s;
          }
        }
      }
    }
  }
}

.scroll-icon {
  position: relative;
  padding-bottom: 10px;
  width: 1.52vw;

  &__mouse {
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: 166%;
    }

    width: 100%;
    border: 1px solid #565e6e;
    border-radius: 999px;
  }

  &__wheel {
    position: absolute;
    top: 13.88%;
    left: 50%;
    width: 18.18%;
    height: 19.44%;
    border: 1px solid #565e6e;
    border-radius: 999px;
    transform: translateX(-50%);
    animation: mouse-wheel 1.2s ease infinite;
  }

  &__arrow {
    margin: 0 auto;
    width: 45.45%;
    border-right: 1px solid #565e6e;
    border-bottom: 1px solid #565e6e;
    transform: rotate(45deg);
    animation: arrows 1.2s ease infinite;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:last-child {
      animation-delay: 0.3s;
    }
  }
}

@keyframes mouse-wheel {
  from {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50%) translateY(200%);
  }
}

@keyframes arrows {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
