.Input {
  position: relative;
  display: block;
  width: 100%;
  height: 32px;
  font-size: 18px;
  line-height: 150%;
  color: #1a2b47;
  border-bottom: 1px solid #a9b3c1;
  transition: border-color 0.3s;

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    line-height: 150%;
    color: #5f7391;
    transition: font-size 0.3s, transform 0.3s, color 0.3s;
  }

  &_error {
    border-color: #b51d32 !important;
    .Input__label {
      color: #b51d32 !important;
    }
  }

  &_focused &,
  &_stored & {
    &__label {
      font-size: 14px;
      color: #a9b3c1;
      transform: translateY(-100%);
    }
  }

  &_focused,
  &_stored {
    border-color: #5f7391;
  }

  input {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    font: inherit;
    background-color: transparent;
  }
}
