@import '../../assets/scss/mixins';

.MainMenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -32px;

  &__item {
    margin: 0 32px;
    color: #a6a8b8;
    font-weight: bold;
    @include fluid-type(1440px, 1920px, 16px, 23px);
    transition: color 0.3s;

    &:hover {
      color: #017be5;
    }

    &_active {
      color: #1a2b47;
    }
  }
}

.Footer {
  .MainMenu {
    &__item {
      &_active {
        color: #fff;
      }
    }
  }
}
