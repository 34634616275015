@import '../scss/mixins';
.content {
  // width: 100%;
  margin: 0 9.37%;

  @include max_991 {
    margin: 0;
    padding: 0 30px;
  }
}

.align-items-center {
  align-items: center;
}

.d-none {
  display: none;
}

.d-lg-block {
  @include _992 {
    display: block;
  }
}

.d-lg-flex {
  @include _992 {
    display: flex;
  }
}

.d-lg-none {
  @include _992 {
    display: none;
  }
}
