@import '../../assets/scss/mixins';
.Fullpage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100vh;

  @include max_991 {
    align-items: flex-end;
    padding-bottom: 0;
    min-height: 100vh;
    max-height: none;
    overflow: hidden;

    &__mobile-circle {
      width: 142.93%;
      position: absolute;
      left: -30.93%;
      top: 31.63%;
      border-radius: 50%;
      background-image: radial-gradient(
        33.23% 33.23% at 50% 50%,
        #f4f3f3 0%,
        rgba(244, 243, 243, 0.75) 65.64%,
        rgba(244, 243, 243, 0.52) 84.14%,
        rgba(244, 243, 243, 0) 100%
      );
      z-index: 1;
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    .content {
      z-index: 2;
    }
  }

  &__inner {
    width: 100%;
    height: auto;
  }

  &_active {
    display: flex;
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(31.9% 83.03% at 26.51% 53.21%, rgba(244, 244, 244, 0.63) 0%, #f4f3f3 100%);
    transition: opacity 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99), transform 0.6s cubic-bezier(0.17, 0.24, 0.19, 0.99);
    opacity: 0;
    transform: scale(2);
  }
}

.FullpageWrapper {
  .Fullpage {
    display: none;

    &_active {
      display: flex;
    }
  }
}

.fullpage-animation {
  &-enter {
    .Fullpage {
      &__overlay {
        transform: scale(2);
        opacity: 0;
      }
    }

    &-active,
    &-done {
      .Fullpage {
        &__overlay {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}
