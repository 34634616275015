@import '../../assets/scss/mixins';
.NewsDetail {
  .News-header {
    padding-top: 16.88vh;
    margin-bottom: 0;

    &__back {
      margin-bottom: 4.79vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      color: #1a2b47;

      @include fluid-type(1440px, 1920px, 16px, 21px);

      svg {
        margin-right: 12px;
      }

      &_right {
        text-align: right;
        justify-content: flex-end;

        @include max_991 {
          margin-top: 45px;
        }

        svg {
          margin-right: 0;
          margin-left: 12px;
        }
      }
    }

    .caption {
      @include max_991 {
        font-size: 24px;
      }
    }

    &__name-column {
      @include max_991 {
        order: -1;
      }
    }
  }

  &__image {
    width: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 8px 28px rgba(35, 58, 80, 0.25);
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 76.39%;
    }
  }

  &__date {
    margin-bottom: 3.83vh;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #017be5;

    @include fluid-type(1440px, 1920px, 18px, 23px);
  }

  &__description {
    margin-top: 3.83vh;
    font-size: 22px;
    line-height: 150%;
    color: #5f7391;

    @include fluid-type(1440px, 1920px, 18px, 29px);
  }
}

.article-body {
  font-size: 18px;
  line-height: 150%;
  color: #5f7391;
  background-color: #fff;
  padding-top: 6.22vh;
  padding-bottom: 7.3vh;

  @include fluid-type(1440px, 1920px, 18px, 29px);

  h2 {
    margin-top: 9.58vh;
    margin-bottom: 6.7vh;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: #1a2b47;

    @include fluid-type(1440px, 1920px, 36px, 47px);

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    color: #1a2b47;

    @include fluid-type(1440px, 1920px, 22px, 29px);

    @include max_991 {
      margin-bottom: 32px;
    }
  }

  img,
  .youtube-embed-wrapper {
    margin: 3.59vh 0;
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
  }

  & > img {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 3.59vh;
    li {
      margin-bottom: 3.59vh;
      position: relative;
      padding-left: 23px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #0170e3;
      }
    }
  }

  b,
  strong {
    font-weight: 500;
    color: #1a2b47;
  }

  ol {
    padding-left: 20px;
    list-style: decimal;

    li {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.article-others {
  background-color: #f5f4f4;
  padding-top: 6.7vh;
  padding-bottom: 10vh;

  @include max_991 {
    padding-bottom: 0;

    .caption {
      font-size: 24px;
    }
  }
}
