@import '../../../assets/scss/mixins';
.MapPoint {
  position: relative;

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    padding: 11.76% 11.77%;
    background: #017be5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: 0.3s;
  }

  &__text-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 99999px;
    background-color: #f8d91c;
    overflow: hidden;
    padding-left: 0%;
    transition: 0.3s;
    max-width: 100%;
    opacity: 0;
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 9px;
    padding-right: 19px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    @include fluid-type(1440px, 1920px, 12px, 16px);
  }

  &:hover & {
    &__text-container {
      padding-left: 100%;
      max-width: 300%;
      opacity: 1;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    img {
      display: block;

      @include max_991 {
        max-width: 70%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &__angle {
    position: absolute;
    width: 68.63%;
    left: 50%;
    top: 85%;
    transform: translateX(-50%);

    svg {
      width: 100%;
      height: auto;
      display: block;
      transition: 0.3s;
      fill: #017be5;
    }
  }

  &_yellow:hover & {
    &__circle {
      background: #f7c91d;
    }
    &__angle {
      svg {
        fill: #f7c91d;
      }
    }
  }

  &__pulse {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(1, 125, 229, 0.3);
    opacity: 0.7;
    border: 1px solid #017de5;
    top: 85%;
    left: 50%;
    transform: translateX(-50%) scale(0);
    border-radius: 50%;
    animation: pulsating 6s infinite;
  }
}

@keyframes pulsating {
  0% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  40%,
  100% {
    transform: translateX(-50%) scale(3);
    opacity: 0;
  }
}
