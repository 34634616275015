@import '../../assets/scss/mixins';

.CasesList {
  @include max_991 {
    &__row {
      margin-top: -32px;
      .col-12 {
        margin-top: 32px;
      }
    }
  }
}
