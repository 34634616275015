@import '../../../assets/scss/mixins';
.LangSwitcher {
  position: relative;
  list-style: none;

  &__toggle {
    padding: 12px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid #9498aa;
    box-sizing: border-box;
    border-radius: 1000px;
    color: #9498aa;
    @include fluid-type(1440px, 1920px, 16px, 23px);
    cursor: pointer;
    transition: stroke 0.3s, color 0.3s, border-color 0.3s, background-color 0.3s;

    svg {
      width: 14%;
      display: block;
      margin-left: 5%;
      stroke: #9498aa;
      transition: stroke 0.3s, transform 0.3s;
    }

    &:hover {
      background-color: #fff;
      border-color: #017be5;
      color: #017be5;

      svg {
        stroke: #017be5;
      }
    }
  }
  &__container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 120%;
    left: 0;
    height: 0;
    z-index: 3;
  }
  &__items {
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 16px 4px;
  }

  &__item {
    padding: 4px 0;
    text-align: center;
    color: #5f7391;
    @include fluid-type(1440px, 1920px, 16px, 23px);
    font-weight: bold;
    border-radius: 9999px;
    transition: background-color 0.3s;
    cursor: pointer;

    &_active {
      color: #a9b3c1;
    }

    &:hover:not(&_active) {
      background-color: #eef1f8;
      color: #017be5;
    }
  }

  &_opened & {
    &__toggle {
      color: #017be5;
      background-color: #fff;
      svg {
        transform: rotate(180deg);
        stroke: #017be5;
      }
    }
  }
}
