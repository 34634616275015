.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__arrow {
    border: 1px solid #a9b3c1;
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: #5f7391;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s, stroke 0.3s;

    &_prev {
      margin-right: 22px;
    }

    &_next {
      margin-left: 22px;
    }

    &:hover {
      stroke: #017be5;
      border-color: #017be5;
      background-color: #fff;
    }
  }

  &__list {
    margin: 0 -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 0 4px;
    margin: 0 4px;
    font-size: 16px;
    line-height: 150%;
    color: #a9b3c1;
    transition: color 0.3s;
    cursor: pointer;

    &:hover:not(&_active) {
      color: #000;
    }

    &_active {
      color: #017be5;
    }
  }
}
