@import '../scss/mixins';

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria/Geometria.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria/Geometria-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria/Geometria-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

// @font-face {
//   font-family: 'Geometria';
//   src: url('../fonts/Geometria/Geometria-Bold.ttf') format('truetype');
//   font-weight: 700;
// }

html,
body {
  font-family: Geometria, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

[class*='col-'] {
  position: relative;
}

.caption {
  font-weight: bold;

  &_1 {
    margin-bottom: $m-3;
    color: #1a2b47;
    line-height: 100%;

    @include fluid-type(1440px, 1920px, 30px, 44px);

    @include max_991 {
      font-size: 42px;
      margin-bottom: 16px;
    }

    @include max_320 {
      font-size: 32px;
    }
  }

  b,
  strong {
    color: #0170e3;
  }
}

.paragraph {
  margin: $m-3 0;
  color: #5f7391;
  line-height: 150%;
  @include fluid-type(1440px, 1920px, 18px, 23px);

  @include max_991 {
    font-size: 20px;
    margin: 16px 0;
  }

  @include max_320 {
    font-size: 14px;
  }

  &_dark {
    color: #413e52;
  }
}

.gradient-text {
  margin-top: $m-6;
  font-weight: bold;
  color: #017be5;
  line-height: 150%;
  @include fluid-type(1440px, 1920px, 22px, 29px);

  @include max_991 {
    font-size: 20px;
    margin-top: 0px;
  }

  strong {
    display: block;
    font-size: 56px;
    line-height: 120%;
    @include fluid-type(1440px, 1920px, 56px, 73px);
  }

  & + .paragraph {
    margin: 0;
  }
}

.mb-3 {
  margin-top: $m-3;
}

.mb-5 {
  margin-bottom: $m-5;
}

.mb-6 {
  margin-bottom: $m-6;
}

.ta-center {
  text-align: center;
}

.Layout {
  position: relative;
  background-color: #f4f3f3;
  padding-bottom: 12.45vh;
  z-index: 2;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}
