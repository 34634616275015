@import '../../assets/scss/mixins';
.NewsList {
  @include max_991 {
    margin-top: 46px;
  }

  &__row {
    margin-top: -6.7vh;
  }

  &__container {
    margin-bottom: 3.83vh;
    padding-bottom: 6.7vh;
    border-bottom: 1px solid #a9b3c1;
  }

  &__loadmore {
    width: 100%;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #9498aa;
    padding: 15px 0;
    border: 1px solid #9498aa;
    box-sizing: border-box;
    border-radius: 999px;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;

    @include fluid-type(1440px, 1920px, 16px, 21px);

    &:hover {
      color: #017be5;
      background-color: #fff;
      border-color: #017be5;
    }
  }
}
