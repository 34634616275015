.NotFound {
  background-image: url('../../assets/images/common/background-circles.svg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  .content {
    height: 100%;
    width: 100%;
  }

  &__container {
    height: 100%;

    & > .row {
      height: 100%;
      align-items: center;
    }
  }
}
